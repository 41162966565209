<template>
  <div :class="$vuetify.breakpoint.xsOnly?'justify-end':'justify-center align-center'"
       class="grey lighten-4 d-flex flex-column"
       style="min-height: 100vh"
  >
    <div
        v-if="restaurantInfo"
        :class="$vuetify.breakpoint.xsOnly?'px-2':''" class="d-flex flex-column align-center text-center py-4 pt-6"
    >

      <v-img :src="restaurantInfo.logoUrl" contain height="72"></v-img>

      <div class="text-h5 font-weight-black mt-2">
        {{ restaurantInfo.shopName }}
      </div>
    </div>
    <v-card
        :height="$vuetify.breakpoint.xsOnly?'90vh':''"
        :width="$vuetify.breakpoint.xsOnly?'100%':'500px'"
        class="mt-2 py-6 rounded-xl" color="white"

        elevation="0">
      <div
          :class="$vuetify.breakpoint.xsOnly?'px-4':'px-8'">
        <div class="text-body-1">
          {{ dayjs(reservationInfo.fromDateTime).format('DD.MM, YYYY, dddd') }}
        </div>
        <div class="text-h4 font-weight-black mt-2">
          {{ dayjs(reservationInfo.fromDateTime).format('HH:mm') }} -
          {{ dayjs(reservationInfo.toDateTime).format('HH:mm') }}
        </div>
        <div class="d-flex mt-8">
          <div>
            <div class="text-body-2">
              {{ $t('Guests') }}
            </div>
            <div class="text-h6 font-weight-bold">
              {{ reservationInfo.personCount }}
            </div>
          </div>
        </div>
      </div>

      <v-divider class="my-6"></v-divider>
      <div :class="$vuetify.breakpoint.xsOnly?'px-4':'px-8'">
        <div class="d-flex">
          <div>
            <div class="text-body-2">
              {{  $t('Name')  }}
            </div>
            <div class="text-h6 font-weight-bold">
              {{ reservationInfo.title }} {{ reservationInfo.firstName }} {{ reservationInfo.lastName }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="text-end">
            <div class="text-body-2">
              {{ $t('Stroller') }}
            </div>
            <div class="text-h6 font-weight-bold">
              {{ reservationInfo.useStroller ? $t('Ja') : $t('Nein') }}
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="text-body-2">
            {{ $t('Note') }}
          </div>
          <div class="text-h6 font-weight-bold">
            {{ reservationInfo.note || '-' }}
          </div>
        </div>
        <div class="mt-4">
          <div class="grey lighten-5 pa-4 rounded-xl mt-2 d-flex align-center justify-center">
            <div style="width: fit-content">
              <v-img
                  :src="' https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='
                  +JSON.stringify({id:reservationInfo.id})"
                  :width="150"></v-img>
            </div>

          </div>
        </div>
        <div class="mt-4">
          <div class="text-body-2">
            {{ $t('Status') }}
          </div>
          <div class="text-h6 font-weight-bold">
            {{$t(reservationInfo.status)}}
          </div>
        </div>
        <div v-if="editable" class="mt-8">
          <div class="text-body-2">
            {{ $t('PlanChanges') }}
          </div>
          <v-btn
              :loading="loading"
              block class="text-none mt-2 text-body-1 font-weight-black" color="primary" height="52"
              outlined @click="showChangeTimeDialog=true">
            <v-icon left>mdi-calendar-arrow-right</v-icon>
            {{ $t('ChangeTime') }}
          </v-btn>
          <v-btn
              :loading="loading"
              block
              class=" mt-2 text-none text-body-1 font-weight-black" color="primary" height="52" outlined @click="showConfirmDialog=true">
            <v-icon left>mdi-cancel</v-icon>
            {{ $t('CancelReservation') }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="showChangeTimeDialog" max-width="500px">
      <v-card class="pa-8" rounded="xl">
        <div class="text-h4 font-weight-black">
          {{ $t('YourReservationIsAlreadyConfirmed') }}
        </div>
        <div class="mt-4">
           {{ $t('PleaseContactTheRestaurantToChangePlan', {restaurantName: restaurantInfo.shopName} ) }}<a
            :href="'tel:'+restaurantInfo.shopPhone">{{ restaurantInfo.shopPhone }}</a> {{ $t('ToEditPlan') }}
        </div>
        <div class="mt-4">
          <v-btn color="black white--text" elevation="0" @click="showChangeTimeDialog=false">{{ $t('OK') }}</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showConfirmDialog" max-width="500px">
      <v-card class="pa-8" rounded="xl">
        <div class="text-h4 font-weight-black">
          {{ $t('Warning') }}
        </div>
        <div class="mt-4">
          {{ $t('AreOYuSureToCancelReservation') }}
        </div>
        <div class="mt-4">
          <v-btn class="mr-2" outlined @click="showConfirmDialog=false">{{ $t('No') }}</v-btn>
          <v-btn color="black white--text" elevation="0" @click="cancelReservation">{{ $t('Yes') }}</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {cancelReservationByToken, reservationCanEdit} from "@/repository/api";

export default {
  name: "DetailPage",
  props: {
    reservationInfo: {},
    restaurantInfo: {},
    token: {}
  },
  data: function () {
    return {
      loading: false,
      showConfirmDialog: false,
      showChangeTimeDialog: false,
    };
  },
  computed: {
    editable() {
      return reservationCanEdit(this.reservationInfo)
    }
  },
  methods: {
    dayjs,
    async cancelReservation() {
      this.loading = true
      this.showConfirmDialog = false
      await cancelReservationByToken(this.token
      )
      this.loading = false
      location.reload()
    }

  }
}
</script>

<style scoped>

</style>
