
const de = require('./locales/de.json')
const zh = require('./locales/zh-Hans-CN.json')
const en = require('./locales/en-US.json')
const nl = require('./locales/nl.json')
const vi = require('./locales/vi.json')

const supported = ['de', 'zh', 'nl', 'en', 'vi']
let locale = 'en'

try {
    // get browser default language
    const {0: browserLang} = navigator.language.split('-')

    if (supported.includes(browserLang)) {
        locale = localStorage.getItem('locale') ?? browserLang
    }
} catch (e) {
    console.log(e)
}


export default {
    // current locale
    locale,

    // when translation is not available fallback to that locale
    fallbackLocale: 'zh',

    // availabled locales for user selection
    availableLocales: [
        {
            code: 'de',
            flag: 'de',
            label: 'Deutsch',
            messages: de
        }, {
            code: 'zh',
            flag: 'cn',
            label: '中文',
            messages: zh
        },{
            code: 'nl',
            flag: 'nl',
            label: 'Dutch',
            messages: nl
        },{
            code: 'en',
            flag: 'en',
            label: 'English',
            messages: en
        }, {
            code: 'vi',
            flag: 'vi',
            label: 'Tiếng Việt',
            messages: vi
        },
    ]
}

