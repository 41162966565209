import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import GlobalConfig from "./GlobalSettings"
import hillo from "hillo"

if (GlobalConfig.base) {
  hillo.initial("https://" + GlobalConfig.base)
}

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
