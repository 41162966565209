import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from './locales'

const {locale, availableLocales, fallbackLocale} = locales

/**
 * Vue Translations
 * https://kazupon.github.io/vue-i18n/
 */
Vue.use(VueI18n)

const messages = {}

availableLocales.forEach((l) => {
  messages[l.code] = l.messages
})

export const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages
})

i18n.locales = availableLocales

export default i18n
