<template>
  <v-app>
    <v-main>
      <template v-if="loading">
        <div style="height: 100vh;" class="d-flex align-center justify-center black">
          <v-progress-circular indeterminate color="white"></v-progress-circular>
        </div>
      </template>
      <template v-else-if="!currentInfo">
        <home-page :restaurant-info="restaurantInfo"/>
      </template>
      <template v-else>
        <detail-page
            :token="token"
            :restaurant-info="restaurantInfo"
            :reservation-info="currentInfo"
        />
      </template>

    </v-main>
  </v-app>
</template>

<script>

import HomePage from '@/views/HomePage.vue'
import IKUtils from "innerken-js-utils";
import {getReservationByToken, getUserList, reportToCloud} from "@/repository/api";
import DetailPage from "@/views/DetailPage.vue";

export default {
  name: 'App',
  components: {DetailPage, HomePage},
  data: function () {
    return {
      token: '',
      restaurantInfo: null,
      loading: true,
      currentInfo: null,
    };
  },
  async mounted() {
    this.loading = true
    this.token = IKUtils.getQueryString('token')
    const subDomain = location.hostname.split('.')[0]
    const testDomains = ['localhost', 'aaden-reservation-front']
    const host = testDomains.includes(subDomain) ? 'aaden'
        : subDomain
    this.restaurantInfo = (await getUserList()).find(it => it.subDominName.toLowerCase() === host.toLowerCase())
    console.log(this.restaurantInfo)
    try {
      await reportToCloud({
        deviceId:this.restaurantInfo.id,
        ip: "",
        uuid: location.host,
        version: require("../package.json").version,
        frontendType: "reservation-customer",
        name: this.restaurantInfo.shopName,
      })
    } catch (e) {
      console.log(e)
    }
    try {
      this.currentInfo = await getReservationByToken(this.token)
    } catch (e) {
      console.log(e, 'catch')
    }


    this.loading = false
  }
}
</script>

<style>
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  padding-right: 8px !important;
}

</style>
