import defaultConfig from '@/assets/config.json'
import config from '../public/Resource/config.json'

let GlobalConfig = {
  base: 'reservation-api.aaden.io/'
}


GlobalConfig = Object.assign(GlobalConfig, defaultConfig, config)



export default GlobalConfig
