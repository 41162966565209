<template>
  <div
      :class="$vuetify.breakpoint.xsOnly?'justify-end':'justify-center align-center'"
      class="white d-flex flex-column"
      style="min-height: 100vh"
  >
    <div
        v-if="restaurantInfo"
        :class="$vuetify.breakpoint.xsOnly?'px-2':''" class="d-flex flex-column align-center text-center py-4 pt-6"
    >

      <v-img :src="restaurantInfo.logoUrl" contain height="72"></v-img>

      <div class="text-h5 font-weight-black mt-2">
        {{ restaurantInfo.shopName }}
      </div>
    </div>
    <div>
      <v-card
          :class="$vuetify.breakpoint.xsOnly?'pa-4':'pa-8'"
          :height="$vuetify.breakpoint.xsOnly?'90vh':''"
          :width="$vuetify.breakpoint.xsOnly?'100%':'500px'" class="mt-2 py-12 rounded-xl"
          color="grey lighten-5"
          elevation="0"
      >

        <template v-if="step===0">
          <div class="text-body-1 font-weight-black">
            {{ $t('FindTableForEveryOccasion') }}
          </div>
          <div class="mt-4" style="display: grid;grid-template-columns: 1fr 0.5fr;grid-gap: 12px">
            <v-text-field
                v-model="date"
                :disabled="timeLoading"
                :return-value.sync="date"
                :rules="rules.datumUndZeit"
                append-icon="mdi-menu-down"
                filled
                hide-details
                prepend-inner-icon="mdi-calendar-month-outline"
                readonly
                style="height: 52px;  "
                @click="showDateSelect=true"
                @click:append="showDateSelect=true"
            />
            <v-select
                v-model="form.personCount"
                :items="personOptionList"
                :rules="rules.datumUndZeit"
                filled
                hide-details
                item-color="red"
                prepend-inner-icon="mdi-account-outline"
            >
            </v-select>
          </div>
          <template v-if="timeLoading">
            <v-progress-linear class="my-8" height="8" indeterminate rounded></v-progress-linear>
          </template>
          <template v-else>
            <template v-if="availableTime.length>0">
              <div class="mt-4">
                <v-select
                    v-model="time"
                    :items="availableTime"
                    :rules="rules.datumUndZeit"
                    filled
                    hide-details
                    item-color="red"
                    item-text="display"
                    item-value="startTime"
                    prepend-inner-icon="mdi-clock-outline"
                >
                </v-select>
              </div>
              <div class="my-4">
                <v-btn
                    block class="text-none text-body-1 font-weight-black"
                    color="primary"
                    x-large @click="step=1"
                >
                  {{ $t('LetsGo') }}
                </v-btn>
              </div>
            </template>
            <template v-else>
              <div class="text-h6 mt-4">
                🙅‍ {{ $t('WeFoundNoAvailableTime') }}
              </div>
            </template>
          </template>
        </template>
        <template v-else-if="step===1">
          <div class="d-flex align-center">
            <v-btn class="text-body-2 text-none font-weight-black" color="grey lighten-3" elevation="0" @click="step=0">
              <v-icon left>mdi-arrow-left</v-icon>
              {{ $t('Back') }}
            </v-btn>
            <v-spacer></v-spacer>

          </div>

          <div class="my-4">
            <div class="d-flex flex-wrap align-center">
              <div class="text-body-1 font-weight-black">
                {{ $t('FillUpGuestDetails') }}
              </div>
              <div class="text-truncate font-weight-bold text-caption" style="width: 100%;">
                *{{ $t('thisRequired') }}
              </div>
            </div>
          </div>


          <v-form
              ref="form"
              v-model="formIsOk"
              lazy-validation
          >
            <v-select
                v-model="form.title"
                :items="titleList"
                :label="$t('selectCallName')"
                filled
            />
            <div style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 8px;width: 100%">

              <v-text-field
                  v-model="form.firstName"
                  :label="$t('firstName')"
                  :rules="rules.require"
                  filled
                  name="firstName"
              />
              <v-text-field
                  v-model="form.lastName"
                  :label="$t('lastName')"
                  :rules="rules.require"
                  filled
                  name="lastName"

              >
              </v-text-field>
            </div>

            <v-text-field
                v-model="form.email"
                :label="$t('email')"
                :rules="rules.require"
                filled
            />

            <v-text-field
                v-model="form.tel"
                :label="$t('tel')"
                :rules="rules.require"
                filled
            >
            </v-text-field>

            <v-select
                v-model="form.useStroller"
                :items="strollerItem"
                :label="$t('needBabyCar')"
                :menu-props="{maxWidth: '150px'}"
                filled
            />

            <v-textarea
                v-model="form.note"
                :label="$t('textWord')"
                filled

            >

            </v-textarea>
          </v-form>

          <div class="d-flex align-center mb-2 mx-0">
            <v-simple-checkbox v-model="checkEinverstanden" color="#A52320">
            </v-simple-checkbox>
            <div class="text-body-2">
              {{ $t('defaultEatTime', {defaultTime: (activeSelectTime.eatingTimeMinute / 60).toFixed(2)}) }}
              <span style="color: #a52321">*</span>
            </div>
          </div>

          <div class="d-flex align-center mb-4 mx-0">
            <v-simple-checkbox v-model="checkDatenschutz" color="#A52320">
            </v-simple-checkbox>

            <div class="text-body-2">
              {{ $t('readAndAccept') }}<span style="color: #a52321">*</span>
            </div>

          </div>
          <div>
            <v-btn
                :disabled="!formIsOk&&(!checkEinverstanden||!checkDatenschutz)"
                :loading="loading"
                block
                class="white--text mt-4 "
                color="#A52320"
                elevation="0"
                style="height: 56px; border-radius: 10px; elevation: 0"
                @click="addReservation"
            >{{ $t('OK') }}
            </v-btn>
          </div>
          <div class="mt-8 text-caption">
            <div class="font-weight-black"> * {{ $t('rules') }}</div>
            <div>{{ $t('reservationRule') }}
            </div>
          </div>
          <div class="mt-2 text-caption">
            {{ $t('tellAndKeepRule', {email: Config.email}) }}
          </div>

        </template>
        <v-dialog
            v-model="showDateSelect"
            max-width="380"
        >
          <v-card class="pb-6">
            <v-date-picker
                v-model="date"
                :min="today"
                color="#A52320"
                full-width
                lang="de"
                locale="de"
                @input="showDateSelect=false"
            />
          </v-card>
        </v-dialog>
      </v-card>
    </div>


    <v-dialog v-model="successTextDialog" max-width="520px" persistent>

      <v-card class="px-10 py-15">
        <div class="d-flex justify-center mb-7">
          <v-img :src="require('../assets/otherImg/747@2x.png')" contain height="145"></v-img>
        </div>

        <div class="d-flex justify-center flex-wrap">

          <div class="middleTitle24">
            {{ Config.restaurantName }}
          </div>

          <div class="mt-3" style="text-align: center">
            {{ $t('successReservation') }}
          </div>


          <v-btn
              block
              class="white--text mt-15"
              elevation="0"
              height="56px" style="border-radius: 10px; text-transform: none; background-color: #A52320; "
              @click="reset"
          >{{ $t('OK') }}
          </v-btn>

        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorTextDialog" max-width="520px">
      <v-card class="py-15 px-10" rounded="xl">
        <div class="d-flex justify-center mb-7">
          <v-img :src="require('../assets/otherImg/746@2x.png')" contain height="150px"></v-img>
        </div>
        <div class="d-flex align-center flex-column">
          <div class="text-h4 font-weight-black" style="font-size: 28px">{{ $t('Error') }}</div>
          <div class="text-body-2 mt-2 text-center">
            {{ $t('errorText') }}
          </div>
        </div>


        <div class="d-flex justify-center px-3">
          <div class="mt-8" style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr))">
            <div>
              <v-img :src="require('../assets/otherImg/698@2x.png')" contain height="48"/>
              <div class="mt-2 contentText14 text-center">
                <a :href="'tel:'+Config.telephone">{{ Config.telephone }}</a></div>
            </div>
            <div>
              <v-img :src="require('../assets/otherImg/699@2x.png')" contain height="48"/>
              <div class="mt-2 contentText14 text-center px-3">{{ Config.email }}</div>
            </div>
          </div>
        </div>

        <div>
          <v-btn
              block
              class="mt-15 text-none text-body-1"
              elevation="0"
              height="56px"
              @click="reset()"
          >{{ $t('OK') }}
          </v-btn>
        </div>

      </v-card>
    </v-dialog>

    <v-dialog v-model="sorryTextDialog" max-width="520px">
      <v-card class="py-15 px-10">
        <div class="d-flex justify-center">
          <div>
            <v-img :src="require('../assets/otherImg/746@2x.png')" contain height="150"/>
            <div class="text-h4 text-center mt-7 mb-3">Sorry!</div>
          </div>
        </div>
        <div>
          <div class="mb-7 text-body-1" style="text-align: center;">
            {{ $t('WveFoundOtherPossibleTimeSlots') }}
          </div>
          <template v-if="availableTime.length>0">
            <div>
              <v-card
                  v-for="t in availableTime.slice(0,5)"
                  :key="t.startTime"
                  :color="t.startTime===time?'primary white--text':'grey lighten-4'"
                  class="pa-4 mb-2 d-flex align-center"
                  flat
                  @click="time=t.startTime"
              >
                {{ t.display }}
                <v-spacer></v-spacer>
                <v-icon v-if="time===t.startTime" color="white">mdi-check</v-icon>
              </v-card>
            </div>
          </template>
          <v-btn
              block
              class="white--text mt-10"
              color="#A52320"
              elevation="0"
              height="52"
              style="border-radius: 10px"
              @click="sorryHandel"

          >{{ $t('OK') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alreadyBooked" max-width="520px">
      <v-card class="py-15 px-10" rounded="xl">
        <div class="d-flex justify-center">
          <div>
            <v-img :src="require('../assets/otherImg/746@2x.png')" contain height="150"/>
            <div class="text-h3 font-weight-black text-center mt-8">{{ $t('Sorry') }}</div>
          </div>
        </div>
        <div class="d-flex mt-6 justify-center flex-wrap">

          <div class="mb-7" style="text-align: center;">
            {{ alreadyBookedText }}
          </div>
          <v-btn
              block
              class="white--text mt-10 mx-10"
              color="#A52320"
              elevation="0"
              height="52"
              style="border-radius: 10px"
              @click="confirmAlreadyBooked"

          >{{ $t('OK') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {checkTableTimeAvailable, webAddReservation} from "@/repository/api"
import dayjs from 'dayjs'
import GlobalConfig from '@/GlobalSettings'
import IKUtils from "innerken-js-utils";
import i18n from "@/i18n";
// import _ from 'lodash'
export default {
  name: 'BestellOnlineComponent',
  // name: 'App',
  props: {
    restaurantInfo: {}
  },
  data() {
    return {
      titleList: [{text:this.$t('Herr'),value:this.$t('Herr')},{text:this.$t('Frau'),value:this.$t('Frau')},{text:this.$t('ohneAnrede'),value:''}],
      personOptionList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      reselectTimeList: [],
      availableTime: [],
      date: dayjs().format('YYYY-MM-DD'),
      time: null,
      timeLoading: false,
      step: 0,
      formIsOk: null,
      eatingTime: '',
      showDateSelect: false,
      alreadyBookedText: null,
      userId: null,

      personCountRule: -1,
      personRules: null,
      tableRules: null,
      loading: false,


      form: {
        userId: '',
        fromDateTime: '',
        personCount: 4,
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        company: 'com',
        note: '',
        childCount: 0,
        useStroller: 0

      },


      sorryAnotherTime: -1,
      // 暂时Dialog
      successTextDialog: false,
      errorTextDialog: false,
      sorryTextDialog: false,
      alreadyBooked: false,

      showTipToSelectTime: false,

      tablePictureUrl: '',
      tableText: null,

      checkEinverstanden: null,
      checkDatenschutz: null,

      strollerItem: [
        {text: this.$t('Nein'), value: 0},
        {text: this.$t('Ja'), value: 1}
      ],

      today: dayjs().format('YYYY-MM-DD'),
      Config: GlobalConfig,
      regelText: '',
      rules: {
        datumUndZeit: [v => !!v || i18n.t('selectTimeDate')],
        require: [v => !!v || i18n.t('cantBeNull')]
      },

    }
  },
  watch: {
    async date() {
      await this.refreshTime()
    },
    async personCount() {
      await this.refreshTime()
    },
  },
  computed: {

    activeSelectTime() {
      return this.availableTime.find(it => it.startTime === this.time)
    },
    personCount() {
      return this.form.personCount
    },

  },
  async mounted() {
    this.userId = this.restaurantInfo.id
    this.Config.email = this.restaurantInfo.shopEmail
    this.Config.restaurantName = this.restaurantInfo.shopName
    this.Config.telephone = this.restaurantInfo.shopPhone
    await this.refreshTime()
  },
  methods: {
    dayjs,
    async refreshTime() {
      this.timeLoading = true
      this.availableTime = (await checkTableTimeAvailable(
          this.date,
          this.form.personCount,
          this.userId
      ))
      this.time = this.availableTime?.[0]?.startTime
      this.timeLoading = false
    },


    async addReservation() {
      this.loading = true
      try {
        this.form.fromDateTime = this.date + ' ' + this.time
        this.form.userId = this.userId
        if (this.form.title === this.$t('ohneAnrede')) {
          this.form.title = ''
        }
        if (this.checkDatenschutz &&
            this.checkEinverstanden &&
            this.$refs.form.validate()
        ) {
          let res = await webAddReservation(this.form)
          if (res.code === 200) {
            this.successTextDialog = true
          } else if (res.data.includes("No table is free")) {
            this.sorryTextDialog = true
            await this.refreshTime()
          } else if (res.data.includes("You already have an reservation")) {
            this.alreadyBooked = true
            this.alreadyBookedText = (this.form.title === this.$t('Herr') ?
                    this.$t('sir') : this.form.title === this.$t('Frau') ?
                        this.$t('madam') : this.$t('hallo')) +
                this.form.lastName +
                this.$t('useOtherEmail')
          } else {
            this.errorTextDialog = true
          }
        } else {
          IKUtils.showError(this.$t('successInfoShouldWrite'))
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }


    },

    async sorryHandel() {
      this.sorryTextDialog = false
      this.form.fromDateTime = ''
      await this.addReservation()
    },
    async reset() {
      this.successTextDialog = false
      this.errorTextDialog = false
      this.step = 0
      this.checkDatenschutz = false
      this.checkEinverstanden = false
      this.sorryAnotherTime = -1
      this.date = dayjs().format('YYYY-MM-DD')
      await this.refreshTime()

    },
    confirmAlreadyBooked() {
      this.alreadyBooked = false
      this.form.email = ''
    },
  }


}
</script>
